import HKStation from "./assets/images/HKStation.png";
import CausewayBayPlaza2 from "./assets/images/CausewayBayPlaza2.png";
import CentralHarbourfront from "./assets/images/CentralHarbourfront.jpeg";
import HKCEC from "./assets/images/HKCEC.png";
import AlliedKajimaBuilding from "./assets/images/AlliedKajimaBuilding.png";
import BusLogo1 from "./assets/images/BusLogo1.png";
import BusLogo2 from "./assets/images/BusLogo2.png";

export const busStopLocations = [
  {
    name: "Causeway Bay Plaza 2",
    lat: 22.280299,
    lng: 114.182091,
  },
  {
    name: "HKAEL Station",
    lat: 22.2850313,
    lng: 114.1580395,
  },
  {
    name: "Allied Kajima Building",
    lat: 22.2793,
    lng: 114.1764,
  },
  {
    name: "HKCEC",
    lat: 22.2832,
    lng: 114.1731,
  },
  {
    name: "Central Harbourfront",
    lat: 22.285881,
    lng: 114.161787,
  },
];

export const hotelLocations = {
  lat: 22.274667,
  lng: 114.179072,
};

export const busStops = [
  "HKAEL Station",
  "Causeway Bay Plaza 2",
  "Allied Kajima Building",
  "HKCEC",
  "Central Harbourfront",
];

export type BusStopTypes = (typeof busStops)[number];

export const busStopCN: { [key: BusStopTypes]: string } = {
  "HKAEL Station": "機場快線香港站",
  "Causeway Bay Plaza 2": "銅鑼灣廣場二期",
  "Allied Kajima Building": "聯合鹿島大廈",
  HKCEC: "香港會議及展覽中心",
  "Central Harbourfront": "中環海濱活動空間",
};

export const busStopImages: { [key: BusStopTypes]: string } = {
  "HKAEL Station": HKStation,
  "Causeway Bay Plaza 2": CausewayBayPlaza2,
  "Allied Kajima Building": AlliedKajimaBuilding,
  HKCEC: HKCEC,
  "Central Harbourfront": CentralHarbourfront,
};

export const shuttlebuses = ["Shuttle Bus 1", "Shuttle Bus 2"];
export type ShuttleBusTypes = (typeof shuttlebuses)[number];

export const shuttleBusImages: { [key: ShuttleBusTypes]: string } = {
  "Shuttle Bus 1": BusLogo1,
  "Shuttle Bus 2": BusLogo2,
};
