import { FC } from "react";
import hotelIconImage from "../assets/images/DwcIcon.png";

const HotelMarkerFont = {
  color: "black",
  fontSize: 16,
  boxShadow: "0 0 0 #add6e4,0 0 0 #add6e4",
  backgroundColor: "#add6e4",
};
interface HotelMarkerProps {
  lat: number;
  lng: number;
}

const BusMarkerWidth = 130;
const BusMarkerHeight = 80;
const HotelMarker: FC<HotelMarkerProps> = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: BusMarkerHeight,
          width: BusMarkerWidth,
          transform:
            "translate(-" +
            BusMarkerWidth / 2 +
            "px, -" +
            BusMarkerHeight / 2 +
            "px)",
        }}
      >
        <img src={hotelIconImage} style={{ width: "50px", height: "50px" }} />
        <div style={{ width: "250px" }}>
          <span style={HotelMarkerFont}>Dorsett Wanchai</span>
        </div>
        <div style={{ width: "250px" }}>
          <span style={HotelMarkerFont}>灣仔帝盛酒店</span>
        </div>
      </div>
    </>
  );
};

export default HotelMarker;
