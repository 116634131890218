import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useQuery } from "@tanstack/react-query";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { TbBus } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
import {
  BusStopTypes,
  ShuttleBusTypes,
  busStopCN,
  busStopLocations,
  busStops,
  hotelLocations,
  shuttleBusImages,
  shuttlebuses,
} from "../Constants";
import { getLatestLocations } from "../api";
import BusMarker from "./BusMarker";
import BusStopMarker from "./BusStopMarker";
import BusStopPopup from "./BusStopPopup";
import HotelMarker from "./HotelMarker";
import CalendarIcon from "./../assets/images/calendar.png";

const MapView = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>();
  const [isFollowBus, setIsFollowBus] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [followedBus, setFollowedBus] = useState<ShuttleBusTypes>(
    searchParams.get("bus") ?? shuttlebuses[0]
  );
  const [selectedBusStops, setSelectedBusStops] =
    useState<BusStopTypes[]>(busStops);
  const [viewingPopup, setViewPopup] = useState<undefined | string>(undefined);
  const { data, fetchStatus } = useQuery(
    ["fetchLatestLocations"],
    getLatestLocations,
    {
      refetchInterval: 5000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: false,
    }
  );

  const defaultProps = {
    center: hotelLocations,
    zoom: 16,
  };

  useEffect(() => {
    if (isFollowBus) {
      const followedBus = searchParams.get("bus") ?? shuttlebuses[0];
      const busData = data?.find((x) => x.busName === followedBus);
      if (busData) {
        setFollowedBus(followedBus);
        setIsFollowBus(true);

        var lat;
        var lng;
        lat = busData.location.latitude;
        lng = busData.location.longitude;
        if (isFollowBus && lat && lng) {
          setMapCenter({ lat, lng });
        }
      } else {
        setIsFollowBus(false);
      }
    } else {
      setMapCenter(undefined);
    }
  }, [data, isFollowBus]);

  useEffect(() => {
    switch (followedBus) {
      case "Shuttle Bus 1":
        setIsFollowBus(true);
        setSelectedBusStops([
          "Allied Kajima Building",
          "HKCEC",
          "Central Harbourfront",
          "HKAEL Station",
        ]);
        break;
      case "Shuttle Bus 2":
        setIsFollowBus(true);
        setSelectedBusStops(["Causeway Bay Plaza 2"]);
        break;
      default:
        setIsFollowBus(false);
        setSelectedBusStops(busStops);
    }
  }, [followedBus]);

  const onBusStopClick = (busStop: string) => {
    setViewPopup(busStop);
  };

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {viewingPopup && (
        <BusStopPopup
          onClose={() => setViewPopup(undefined)}
          name={viewingPopup}
        />
      )}
      <Button
        style={{
          backgroundColor: "white",
          zIndex: 100,
          position: "absolute",
          top: 125,
          left: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 1px 4px -1px rgba(0, 0, 0, 0.3)",
          flexDirection: "column",
          borderRadius: "20%",
          height: "110px",
          width: "110px",
        }}
        onClick={() => {
          setIsFollowBus(!isFollowBus);
        }}
        disabled={
          data?.find(
            (x) =>
              x.busName === searchParams.get("bus") || x.busName === followedBus
          ) === undefined
        }
      >
        {isFollowBus ? (
          followedBus === "Shuttle Bus 1" ? (
            <img
              style={{
                width: 80,
                height: 28,
                padding: 10,
              }}
              src={shuttleBusImages["Shuttle Bus 1"]}
            />
          ) : (
            <img
              style={{
                width: 80,
                height: 28,
                padding: 10,
              }}
              src={shuttleBusImages["Shuttle Bus 2"]}
            />
          )
        ) : (
          <TbBus style={{ padding: 5 }} size={60} color="#000000" />
        )}
        <div
          style={{
            color: isFollowBus ? "DeepSkyBlue" : "grey",
            padding: 5,
            paddingTop: 0,
            whiteSpace: "pre-wrap",
          }}
        >
          <Typography fontSize={10}>
            {isFollowBus ? "Following" : "Click to follow bus"}
          </Typography>
          <Typography fontSize={10}>
            {isFollowBus
              ? "Bus No." + followedBus?.substring(followedBus.length - 1)
              : ""}
          </Typography>
          <Typography fontSize={10}>
            {isFollowBus
              ? "正追蹤 " +
                followedBus?.substring(followedBus.length - 1) +
                "號線巴士"
              : "追蹤巴士"}
          </Typography>
        </div>
      </Button>
      <Button
        style={{
          backgroundColor: "white",
          zIndex: 100,
          position: "absolute",
          top: 5,
          left: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 1px 4px -1px rgba(0, 0, 0, 0.3)",
          flexDirection: "column",
          borderRadius: "20%",
          height: "110px",
          width: "110px",
        }}
        onClick={() =>
          window.open(
            "https://www.wanchai.dorsetthotels.com/pdf/shuttle-bus/bus-schedule.pdf",
            "_blank"
          )
        }
      >
        <img
          style={{
            width: 50,
            height: 40,
            padding: 10,
          }}
          src={CalendarIcon}
        />
        <div
          style={{
            color: "grey",
            padding: 5,
            paddingTop: 0,
            whiteSpace: "pre-wrap",
          }}
        >
          <Typography fontSize={10}>VIEW SCHEDULE</Typography>
          <Typography fontSize={10}>查看時刻表</Typography>
        </div>
      </Button>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyB7RBquNgU0wOdKsgLeZKloQ2g4HBelRp8" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={mapCenter}
        options={{ gestureHandling: "greedy" }}
        onDrag={(e) => {
          setIsFollowBus(false);
        }}
      >
        {selectedBusStops.map((busStop, i) => {
          const location = busStopLocations.find((x) => x.name === busStop);
          if (location) {
            return (
              <BusStopMarker
                key={busStop}
                name={busStop}
                nameCN={busStopCN[busStop]}
                lat={location.lat}
                lng={location.lng}
                onClick={() => onBusStopClick(busStop)}
              />
            );
          }
        })}
        <HotelMarker lat={hotelLocations.lat} lng={hotelLocations.lng} />
        {data &&
          data
            .filter((d) => {
              if (followedBus === null) {
                return d.busName === searchParams.get("bus");
              } else {
                return d.busName === followedBus;
              }
            })
            .map((d, i) => (
              <BusMarker
                key={i}
                lat={d.location.latitude}
                lng={d.location.longitude}
                busName={d.busName}
                createdAt={d.createdAt}
              />
            ))}
      </GoogleMapReact>
    </div>
  );
};

export default MapView;
