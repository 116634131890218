import dayjs from "dayjs";
import { FC } from "react";
import { shuttleBusImages } from "../Constants";

interface BusMarkerProps {
  busName: string;
  createdAt: string;
  lat: number;
  lng: number;
}

const BusMarkerWidth = 100;
const BusMarkerHeight = 100;
const BusMarker: FC<BusMarkerProps> = (props) => {
  const color = dayjs(props.createdAt).diff() < -10000 ? "#d4d0d0" : "#000000";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: BusMarkerHeight,
        width: BusMarkerWidth,
        borderTopLeftRadius: BusMarkerWidth * 0.3,
        borderTopRightRadius: BusMarkerWidth * 0.3,
        borderBottomLeftRadius: BusMarkerWidth * 0.3,
        borderBottomRightRadius: BusMarkerWidth * 0.3,
        transform:
          "translate(-" +
          BusMarkerWidth / 2 +
          "px, -" +
          BusMarkerHeight / 2 +
          "px)",
        backgroundColor: "white",
        borderRadius: "50%",
      }}
    >
      <img
        src={shuttleBusImages[props.busName]}
        style={{ width: 80, height: 30, padding: 2 }}
      />
      <div
        style={{
          width: BusMarkerWidth,
          marginTop: 0,
          fontSize: 12,
          color: color,
        }}
      >
        {props.busName}
      </div>
    </div>
  );
};

export default BusMarker;
