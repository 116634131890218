import { FC } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { busStopImages } from "../Constants";

interface BusStopPopupProps {
  name: string;
  onClose: () => void;
}

const BusStopPopup: FC<BusStopPopupProps> = (props) => {
  return (
    <div
      style={{
        zIndex: 120,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.3)",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "25px",
          zIndex: 102,
          color: "black",
          padding: 40,
          position: "relative",
          maxWidth: "80%",
          maxHeight: "80%",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 101,
          }}
          onClick={() => props.onClose()}
        >
          <AiFillCloseCircle />
        </div>
        <img
          src={busStopImages[props.name]}
          width={"100%"}
          height={"100%"}
          alt={"Bus Stop"}
        />
      </div>
    </div>
  );
};

export default BusStopPopup;
