import { FC, useLayoutEffect, useRef } from "react";
import busStopImage from "../assets/images/ShuttleBusIcon.png";
import bubbleImage from "../assets/images/bubble.png";
import { useElementSize } from "usehooks-ts";

interface BusStopMarkerProps {
  lat: number;
  lng: number;
  name: string;
  nameCN: string;
  onClick: () => void;
}
const BusStopNameFont = {
  color: "black",
  fontSize: 12,
  fontWeight: "bold",
};

const ViewMoreFont = {
  color: "#2e8ce8",
  fontSize: 12,
  fontWeight: "bold",
  textDecoration: "underline",
};

const BusStopMarker: FC<BusStopMarkerProps> = (props) => {
  const [containerRef, { width, height }] = useElementSize();

  return (
    <div
      onClick={props.onClick}
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transform: "translate(-" + width / 2 + "px, -" + height + "px)",
      }}
    >
      <div
        style={{
          backgroundImage: "url(" + bubbleImage + ")",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "200px",
          minHeight: "120px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            paddingTop: "25px",
            paddingBottom: "40px",
          }}
        >
          <div style={BusStopNameFont}>{props.name}</div>
          <div style={BusStopNameFont}>{props.nameCN}</div>
          <div style={ViewMoreFont}>View more</div>
          <div style={ViewMoreFont}>查看更多</div>
        </div>
      </div>
      <div
        style={{
          height: "50px",
          width: "50px",
          backgroundImage: "url(" + busStopImage + ")",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      />
    </div>
  );
};

export default BusStopMarker;
